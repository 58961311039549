$body-bg: rgb(220, 52, 47);
$second-bg: rgb(220, 52, 47);
$main-color: rgb(220, 52, 47);
$main-color-hover: rgb(220, 52, 47);
$txt-color: #000;
$txt-second-color: #757474;
$white: #fff;

$font-main: "Gilroy-Bold";
$font-second: "Roboto";

$bg-item-1 : #484848;
// $bg-item-2 : #4526b1;
$bg-item-2: #2b2b2b;
$bg-item-3 : #212121;

$team-box-color: rgb(220, 52, 47);

$header-height: 140px;
$header-shrink-height: 90px;

$mobile-small-width: 390px;
$mobile-width: 767px;
$tablet-width: 1024px;
$desktop-width: 1200px;
$desktop-1350: 1350px;
$desktop-1500: 1500px;
$desktop-1900 : 1900px;


$box-shadow: 0 10px 20px rgb(0 0 0 / 5%);
$bg-overlay: rgba(0, 0, 0, 0.5);